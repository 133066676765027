import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { NgbDateStruct, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import 'moment-duration-format';
import { UAParser } from "ua-parser-js";
// Redux
import { Store } from '@ngrx/store';
import * as personalizationClientActions from '../../store/actions/personalizationClient.actions';
import { AppState } from '../../store/app.reducers';
// interfaces
import { IDatosNavegarIpCliente } from '../../interfaces/IDatosNavegarIpCliente';

import { Constants } from '../../../providers/constants/constants';
import { ClientService } from '../../services/client.service';
import { TypesService } from '../../services/types.service';
import { ProcedureService } from '../../services/procedure.service';
import { UserService } from '../../services/user.service';
import { ToolsService } from '../../services/tools.service';
import { AppointmentService } from '../../services/appointment.service';
import { I18nService } from '../../services/i18n.service';
import { CustomDatepickerI18nService } from '../../services/custom.datepickerI18n.Service';
import { ParameterService } from '../../services/parameter.service';
import { Subscription } from 'rxjs';

// lenguaje calendario de fechas
const currentDate = new Date();
const parser = new UAParser();
@Component({
  selector: 'app-agendar-cita-cliente',
  templateUrl: './agendar-cita-cliente.component.html',
  styleUrls: ['./agendar-cita-cliente.component.scss'],
  providers: [I18nService, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18nService }] // define custom NgbDatepickerI18n provider
})
export class AgendarCitaClienteComponent implements OnInit, OnDestroy {
  constants = Constants;
  moment = moment;
  tokenOrganization = '';
  clientSubDomain: any;
  usersClientSearchSubs: Subscription;
  dataIpClienteSubs: Subscription;
  pasoUnoForm: FormGroup;
  pasoDosForm: FormGroup;
  objSelectTypesOfIdentification: any;
  objSelectTypesOfService: any;
  procedures: any = [];
  users: any = [];
  userClient: any = [];
  tipoServicio: string = '';
  parameters: any = [];
  fechaMenorQuelaActual: string = '';
  // datos interfaz personalización cliente
  colorPrimaryAgendateApp = this.constants.colors.primary;
  dataOrganization: any;
  dataPersonalization: any;
  logoCliente = '';
  logoClienteSecundario = '';
  tituloPublicidad = 'Un mensaje en el momento oportuno...<br>Hace la diferencia.';
  descripcionPublicidad = 'Mejora el agendamiento de citas o reservas de una manera mucho más ágil, práctica y confiable.<br><br>Mejora la comunicación con tus clientes a través del envío de recordatorios 24 horas antes de sus citas o eventos.'
  imgFondo = '';
  // datos config datepicker
  @ViewChild('dpAppointment') dpAppointment;
  fechaNgbStrcut: NgbDateStruct;
  listaFechasDisponiblesCitas: any = [];
  fechasDisponiblesEnCalendario: any = [];
  horasDisponiblesPorDia: any = [];
  fechaDisponibleSeleccionada = '';
  horaDisponibleSeleccionada = '';
  stepRegistration = 1;
  valorDelProcedimiento: string = '';
  tiempoProcedimiento: string = '';
  descripcionProcedimiento: string = '';
  dataNavegadorCliente: IDatosNavegarIpCliente = { coordenadas:null, dataIp: null, navegador: null };
  // fin config
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private clientService: ClientService,
    public toolsService: ToolsService,
    private typesService: TypesService,
    private procedureService: ProcedureService,
    private userService: UserService,
    private appointmentService: AppointmentService,
    private parameterService: ParameterService,
    public formBuilder: FormBuilder,
    private elementRef: ElementRef,
    private store: Store<AppState>
  ) { }

  /**
   * @description metodo nativo de angular, se ejecuta apenas entran a la vista
   */
  ngOnInit(): void {
    this.dataNavegadorCliente.navegador = parser.getResult();
    // se cambia el color primario solo mientras carga el ws de personalizacion y asi no mostrar el color principal de agendate
    this.elementRef.nativeElement.style.setProperty('--primary', '#FFFFFF');
    // obtengo los datos que lleguen por parametro
    this.activatedRoute.params.subscribe((params: any) => {
      if (params.clientSubDomain) {
        this.clientSubDomain = params.clientSubDomain;
        this.validateClientOrganization();
      } else {
        this.router.navigate(['/404']);
      }
    });

    this.validatorsFormNewAppointment();
    this.getTypesOfIdentification();
  }

  ngOnDestroy() {
    try {
      this.usersClientSearchSubs?.unsubscribe();
      this.dataIpClienteSubs?.unsubscribe();
    } catch (error) {
      console.log('Error en el ngOnDestroy AgendarCitaClienteComponent');
      console.log(error);
    }
  }

  /**
   * @description se utiliza para cambiar el paso a paso del registro
   * @param step paso a activar en el formulario
   */
   nextStepRegistration(step: number){
    // SCROLL TOP
    window.scrollTo(0, 0);
    this.stepRegistration = step;
  }

  validatorsFormNewAppointment() {
    this.pasoUnoForm = this.formBuilder.group({
      tipoIdentificacion: ['', [Validators.required]],
      identificacion: ['', [Validators.required]],
      nombres: new FormControl('', Validators.compose([
        // Validators.maxLength(25),
        Validators.minLength(4),
        // Validators.pattern('^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$'),
        Validators.required
      ])),
      apellidos: [''],
      nacimiento: [''],
      email: ['', [Validators.required, Validators.email]],
      celulares: ['', [Validators.minLength(10), Validators.maxLength(10), Validators.required]],
      // telefonos: ['', [Validators.minLength(7), Validators.maxLength(7)]],
    });

    this.pasoDosForm = this.formBuilder.group({
      tipoCita: ['', [Validators.required]],
      usuarioAtendedor: ['', [Validators.required]],
      fechaCita: ['', [Validators.required]],
      fechaHora: ['', [Validators.required]],
      nota: ['', [Validators.required]],
      comoTeEnteraste: [''],
      aceptedTerms: [false, Validators.requiredTrue],
    });
  }

  validateClientOrganization() {
    this.store.dispatch(personalizationClientActions.triggerPersonalizationClientInit());
    this.clientService.postClientOrganizationValidate({ sub_dominio: this.clientSubDomain })
      .subscribe((response: any) => {
        const typeResponse = response.type;
        const lengthData = response.data.length;
        if (typeResponse === this.constants.services.types.success) {
          this.dataOrganization = response.data;
          this.dataPersonalization = response.data.personalization;
          this.tipoServicio = this.dataOrganization.type_service;
          // si no existe personalizacion no se aplican los cambios
          if (this.dataPersonalization !== '' && this.dataPersonalization !== undefined){
              this.store.dispatch(personalizationClientActions.triggerPersonalizationClientSuccess({ personalizationCliente: this.dataPersonalization }));
            this.setPersonalization(true);
          }else{
            this.setPersonalization(false);
          }
          this.getProcedure();
          this.getParameters();
        } else if (typeResponse === this.constants.services.types.error) {
          this.elementRef.nativeElement.style.setProperty('--primary', this.colorPrimaryAgendateApp);
          this.toolsService.presentToast(response.message.messageDescription, this.constants.iconsSweetalert.error);
        }
      }, err => {
        this.toolsService.statusService(err);
      }, () => {
      });
  }

  /**
   * @description obtinene los datos de personalizacion de la empresa y los aplica
   */
  setPersonalization(personalizar: boolean = false) {
    if (personalizar) {
      // se aplica la personalizacion que venga del ws
      this.tituloPublicidad = this.dataPersonalization.texto_principal !== '' ? this.dataPersonalization.texto_principal : this.tituloPublicidad;
      this.descripcionPublicidad = this.dataPersonalization.texto_secundario !== '' ? this.dataPersonalization.texto_secundario : this.descripcionPublicidad;
      this.logoCliente = (this.dataPersonalization.logo !== '') ? this.dataPersonalization.logo : '';
      this.logoClienteSecundario = (this.dataPersonalization.logo_secundario !== '') ? this.dataPersonalization.logo_secundario : '';
      this.imgFondo = (this.dataPersonalization.fondo !== '') ? this.dataPersonalization.fondo : '/assets/img/bg-agendar-citas.jpg';
      const colorPrimary = (this.dataPersonalization.color_principal !== '') ? `#${this.dataPersonalization.color_principal}` : this.colorPrimaryAgendateApp;
      this.elementRef.nativeElement.style.setProperty('--primary', colorPrimary);
      // console.log(this.dataPersonalization);
      return;
    }
    this.elementRef.nativeElement.style.setProperty('--primary', this.colorPrimaryAgendateApp);
    this.imgFondo = '/assets/img/bg-agendar-citas.jpg';
  }

  /**
   * @description trae la lista de tipos de identificación existentes
   */
  getTypesOfIdentification() {
    this.typesService.getGroupTypes('Identificacion')
      .subscribe((response: any) => {
        const typeResponse = response.type;
        const lengthData = response.data.length;
        if (typeResponse === this.constants.services.types.success && lengthData > 0) {
          this.objSelectTypesOfIdentification = response.data;
        } else if (typeResponse === this.constants.services.types.error) {
          this.toolsService.presentToast(response.message.messageDescription, this.constants.iconsSweetalert.error);
          this.getTypesOfIdentification();
        }
      }, err => {
        this.toolsService.statusService(err);
      }, () => {
      });
  }

  async getIpCliente() {
    this.dataIpClienteSubs = await this.toolsService.getIpCliente()
      .subscribe((response: any) => {
        this.dataNavegadorCliente.dataIp = response;
      }, err => {
        // this.toolsService.statusService(err);
      }, () => {
      });
  }

  validarFechaNacimiento(){
    this.fechaMenorQuelaActual = '';
    const fecha = this.pasoUnoForm.get('nacimiento')?.value; // es la fecha del select
    const fechaActual = moment().format('YYYY-MM-DD'); // fecha para hacer validaciones
    const fechaMenorQueActual = moment(fechaActual).isAfter(moment(fecha).format('YYYY-MM-DD'));

    // si la fecha es mayor a la actual muestro mensaje de error
    if (!fechaMenorQueActual) {
      // notifico al cliente qeu la fecha debe ser menor a la actual y limpio el campo fecha
      this.fechaMenorQuelaActual = 'La fecha seleccionada debe ser menor a la actual.';
      this.pasoUnoForm.patchValue({nacimiento: ''})
    }
  }

  /**
   * @description trae la lista de items de donde se entero de agendate
   */
  getParameters(){
    const data = JSON.stringify({llave:'enterate'});
    this.parameterService.getParameterByParams(data, this.dataOrganization.token)
    .subscribe((response: any) => {
      const typeResponse = response.type;
      const lengthData = response.data.length;
      if (typeResponse === this.constants.services.types.success && lengthData > 0) {
        this.parameters = response.data[0].propiedades;
      } else if (typeResponse === this.constants.services.types.error) {
        this.toolsService.presentToast(response.message.messageDescription, this.constants.iconsSweetalert.error);
      }
    }, err => {
      this.toolsService.statusService(err);
    }, () => {
    });
  }

  /**
   * @description Se hace llamado al web services que busca los tipos de procedimiento de la empresa
   */
  getProcedure() {
    const data = JSON.stringify({ tipos: [this.tipoServicio], estados: 'activo' });
    this.procedureService.getProcedureSearch(data, this.dataOrganization.token)
      .subscribe((response: any) => {
        const typeResponse = response.type;
        if (typeResponse === this.constants.services.types.success) {
          this.procedures = response.data;
        } else if (typeResponse === this.constants.services.types.error) {
          this.toolsService.presentToast(response.message.messageDescription);
        }
      }, err => {
        this.toolsService.statusService(err);
      }, () => {
      });
  }

  /**
   * @description Se hace llamado al web services que busca usuarios de la cuenta
   */
  getUsersSearch() {
    this.users = [];
    this.pasoDosForm.get('usuarioAtendedor').reset();
    const tipoCita = this.pasoDosForm.get('tipoCita').value;
    const dataTipoCita = this.procedures.filter(procedimiento => procedimiento._id === tipoCita)[0];
    this.valorDelProcedimiento = dataTipoCita?.caracteristicas ? dataTipoCita?.caracteristicas.valor_servicio.valor : '';
    this.tiempoProcedimiento = dataTipoCita?.tiempo_estimado ? dataTipoCita?.tiempo_estimado : '';
    this.descripcionProcedimiento = dataTipoCita?.descripcion ? dataTipoCita?.descripcion : '';
    const data = JSON.stringify({ usuarios: { estados: 'activo', roles: ['atiendecitas', 'administrador'], procedimientos: dataTipoCita } });
    this.userService.getUsersSearch(data, this.dataOrganization.token)
      .subscribe((response: any) => {
        const typeResponse = response.type;
        if (typeResponse === this.constants.services.types.success) {
          if (response.data.length > 0) {
            this.users = response.data;
          } else {
            // se notifica al usuario que no existen usuarios atiende citas y se manda a crear usuario
            this.toolsService.presentToast(this.constants.mensajesInformativos.noUsersAttendAppointmentsNewAppointmentClient.mensaje, this.constants.iconsSweetalert.warning);
          }
        } else if (typeResponse === this.constants.services.types.error) {
          this.toolsService.presentToast(response.message.messageDescription, this.constants.iconsSweetalert.error);
        }
      }, err => {
        this.toolsService.statusService(err);
      }, () => {
      });
  }

  /**
   * @description se consultan los datos del cliente cuando ingresa el tipo de identificacion y numero, si existe se le completan los datos
   * Si no, se dejan los campos vacios para que el usuario los llene y al momento de crear la cita se crea el usuario
   * @param event es el evento que se ejecuta en el change o focusout del formulario
   */
  focusoutConsultarUsuarioCliente(event: any) {
    const tipoIdentificacion = this.pasoUnoForm.get('tipoIdentificacion').value;
    const identificacion = this.pasoUnoForm.get('identificacion').value;

    if (tipoIdentificacion !== null && tipoIdentificacion !== '' && identificacion !== '') {
      const data = JSON.stringify({ usuarios: { estados: 'activo', roles: 'paciente', tipo_identificacion: tipoIdentificacion, identificacion: identificacion } });
      this.usersClientSearchSubs = this.userService.getUsersSearch(data, this.dataOrganization.token)
        .subscribe((response: any) => {
          const typeResponse = response.type;
          if (typeResponse === this.constants.services.types.success && response.data.length > 0) {
            this.userClient = response.data[0];
            const dataClient = {
              // tipoIdentificacion: this.userClient.tipo_identificacion._id,
              // identificacion: this.userClient.identificacion,
              nombres: this.userClient.nombres,
              apellidos: this.userClient.apellidos,
              nacimiento: this.userClient?.nacimiento ? moment(this.userClient?.nacimiento).format('YYYY-MM-DD') : null,
              email: this.userClient.email,
              celulares: this.userClient.celulares[0]
            };
            this.pasoUnoForm.patchValue(dataClient);
            this.pasoUnoForm.updateValueAndValidity();
          } else {
            this.userClient = [];
          }
        }, err => {
          this.toolsService.statusService(err);
        }, () => {
        });
    }
  }

  /**
   * @description resetea los valores que se utilizan para las fechas y horas del calendario de citas disponibles
   */
  resetValoresFechasCitas() {
    this.fechasDisponiblesEnCalendario = [];
    this.listaFechasDisponiblesCitas = [];
    this.horasDisponiblesPorDia = [];
    this.horaDisponibleSeleccionada = '';
    this.fechaDisponibleSeleccionada = '';
    this.pasoDosForm.controls.fechaCita.setValue('');
    this.pasoDosForm.controls.fechaHora.setValue('');
  }

  /**
   * @description valida que se seleccione un usuarioAtendedor y tipoCita para consultar disponibilidad de citas
   */
  changeConsultarDisponivilidadCitas() {
    // se limpia el calendario, horas disponibles e inputs ocultos
    this.resetValoresFechasCitas();
    // con navigateTo se le indica al calendario que se mueva a la fecha que se le manda por parametro
    this.dpAppointment.navigateTo({
      year: parseInt(moment(currentDate).format('YYYY'), 10),
      month: parseInt(moment(currentDate).format('MM'), 10)
    });
    if (this.pasoDosForm.get('usuarioAtendedor').value !== null && this.pasoDosForm.get('usuarioAtendedor').value !== '' &&
      this.pasoDosForm.get('tipoCita').value !== null && this.pasoDosForm.get('tipoCita').value !== '') {
      // se manda la fecha actual para que el servicio solo traiga apartir de la fecha que se manda
      this.disponivilidadCitasPorUsuarioAtendedor(moment(currentDate,'YYYY-MM-DD').format('YYYY-MM-DD'));
    }
  }

  // NOTA: recordarle a victor que se debe agregar el tiempo por defecto a los procedimientos que ya existen en la base de datos
  // por que por ahora le estoy asignado 30 minutos
  disponivilidadCitasPorUsuarioAtendedor(fechaInicial: string = null) {
    const idProcedure = this.pasoDosForm.get('tipoCita').value;
    const tipoProcedimiento = this.procedures.filter(procedure => procedure._id === idProcedure);
    const tiempoEstimado = (tipoProcedimiento[0].tiempo_estimado) ? tipoProcedimiento[0].tiempo_estimado : 30;
    const dateHoraInicial = moment('000', 'hmm').format('HH:mm:ss.SSS');
    const fechaInicialTimestamp = moment(`${fechaInicial}T${dateHoraInicial}`).valueOf();
    const data = {
      usuario_atendedor: this.pasoDosForm.get('usuarioAtendedor').value,
      rango: 'mes', // posibles randos: mes, semana y dia
      fecha: (fechaInicial !== null) ? fechaInicialTimestamp : null,
      procedimientos: idProcedure,
      tiempo_estimado: tiempoEstimado,
      origen: this.constants.origenNewCita[2]
    };

    this.appointmentService.postValidarAgendaAtendedorPorProcedimientoDia(data, this.dataOrganization.token).subscribe((response: any) => {
      const typeResponse = response.type;
      if (typeResponse === this.constants.services.types.success) {
        if (response.data.dias.length > 0) {
          this.listaFechasDisponiblesCitas = response.data.dias;
          // utilizar esta funcionalidad para bloquear dias del mes en los que un atendedor no trabaje,
          // se debe ingresar el id del agendador y el array de fechas en las que no va a trabajar
          // 635aaf6c897a343f3e6180f6 = alex franco citas/peluqueria_alex_franco

          // oculto el dia para todos los atiende citas de la empresa de danny gerez
          if (this.dataOrganization.organization_id === '63a0d53e693938c242d981a1'){
            this.listaFechasDisponiblesCitas = this.listaFechasDisponiblesCitas.filter(fechas => (fechas.fecha !== '2024-12-24' && fechas.fecha !== '2024-12-25' && fechas.fecha !== '2024-12-31' ));
          }

          if(data.usuario_atendedor ==='635aaf6c897a343f3e6180f6'){
            this.listaFechasDisponiblesCitas = this.listaFechasDisponiblesCitas.filter(fechas => (fechas.fecha !== '2023-04-26' && fechas.fecha !== '2023-04-27' && fechas.fecha !== '2023-04-28' && fechas.fecha !== '2023-04-29' && fechas.fecha !== '2023-04-30' && fechas.fecha !== '2023-05-01' && fechas.fecha !== '2023-05-02' && fechas.fecha !== '2023-05-03' ));
          }

          for (const item of this.listaFechasDisponiblesCitas) {
            // YYYY-MM-DD
            if (this.fechasDisponiblesEnCalendario.findIndex(fecha => fecha === item.fecha) === -1) {
              this.fechasDisponiblesEnCalendario.push(item.fecha);
            }
          }
          this.selectToday(this.fechasDisponiblesEnCalendario[0]);
        } else {
          // se notifica al usuario que no existen citas disponibles
          this.toolsService.presentToast(this.constants.mensajesInformativos.noHayCitasDisponibles.mensaje, this.constants.iconsSweetalert.warning);
        }
      } else if (typeResponse === this.constants.services.types.error) {
        this.toolsService.presentToast(response.message.messageDescription, this.constants.iconsSweetalert.error);
      }
    }, err => {
      this.toolsService.statusService(err);
    }, () => {
    });
  }

  /**
   * @description selecciona por defecto la fecha en el calendario,
   * se manda el primer registro de los dias disponibles de fechasDisponiblesEnCalendario
   * @param {string} fechaSeleccionar fecha a seleccionar, llega en formato YYYY-MM-DD
   */
  selectToday(fechaSeleccionar: string) {
    this.fechaNgbStrcut = {
      year: parseInt(moment(fechaSeleccionar, 'YYYY-MM-DD').format('YYYY'), 10),
      month: parseInt(moment(fechaSeleccionar, 'YYYY-MM-DD').format('MM'), 10),
      day: parseInt(moment(fechaSeleccionar, 'YYYY-MM-DD').format('DD'), 10),
    };
    this.pasoDosForm.controls.fechaCita.setValue(this.fechaNgbStrcut);
    this.getHorasDisponiblesPorDia(fechaSeleccionar);
  }

  isDisabled(date: NgbDateStruct, current: { month: number }) {
    return date.month !== current.month;
  }

  hasTask(date: NgbDateStruct) {
    return this.fechaConDisponibilidadDeCitas(date);
  }

  /**
   * @description se activa cuando cambian de mes o año en el calendario
   * @param date fecha que tiene el calendario al activarse el evento navigate del calendario
   */
  navegacionFechas(date: NgbDateStruct) {
    const mesActual = parseInt(moment(currentDate).format('MM'), 10);
    const anoActual = parseInt(moment(currentDate).format('YYYY'), 10);
    // se resetean los valores
    this.resetValoresFechasCitas();
    // date.year >= anoActual && date.month >= mesActual &&
    if ( this.pasoDosForm.get('usuarioAtendedor').value !== null && this.pasoDosForm.get('usuarioAtendedor').value !== ''
     && this.pasoDosForm.get('tipoCita').value !== null && this.pasoDosForm.get('tipoCita').value !== '') {
      // se manda la fecha actual para que el servicio solo traiga apartir de la fecha que se manda
      const dateCalendar = moment(`${date.year}-${date.month}-01`,'YYYY-MM-DD').format('YYYY-MM-DD');
      // console.log(dateCalendar);
      this.disponivilidadCitasPorUsuarioAtendedor(dateCalendar);
    }
    // else if (date.year >= anoActual && (date.month - mesActual) <= 1) {
    //   console.log(date.month);
    //   console.log((date.month - mesActual) <= 1);
    //   console.log(`${date.year}-${date.month}-01`)

    //   // la operacion del if es por que solo se permite consultar el mes siguiente al actual,
    //   // de lo contrario no se permite ver mas disponibilidad de fechas
    //   // this.disponivilidadCitasPorUsuarioAtendedor(`${date.year}-${date.month}-01`);
    // }
  }

  showTasks(date: NgbDateStruct) {
    if (this.fechaConDisponibilidadDeCitas(date)) {
      const ano = date.year;
      const mes = date.month <= 9 ? `0${date.month}` : date.month;
      const dia = date.day <= 9 ? `0${date.day}` : date.day;
      this.getHorasDisponiblesPorDia(`${ano}-${mes}-${dia}`);
    } else {
      this.horasDisponiblesPorDia = [];
    }
  }

  fechaConDisponibilidadDeCitas(date: NgbDateStruct): boolean {
    // solo se renderiza el mes actual  con el del calendario
    // if (currentDate.getMonth() + 1 === date.month) {
    for (const item of this.fechasDisponiblesEnCalendario) {
      const day: number = parseInt(moment(item, 'YYYY-MM-DD').format('DD'), 10);
      const month: number = parseInt(moment(item, 'YYYY-MM-DD').format('MM'), 10);
      const year: number = parseInt(moment(item, 'YYYY-MM-DD').format('YYYY'), 10);

      if (day === date.day && month === date.month && year === date.year) {
        return true;
      }
    }
    // }
  }

  /**
   * @description se recorre el array de fechas inicial listaFechasDisponiblesCitas y se compara con la fecha que llega por parametro
   * se agregan las horas que coinciden con la fecha
   * @param {string} fechaDisponibilidad fecha selecciona en el calendario
   */
  getHorasDisponiblesPorDia(fechaDisponibilidad: string) {
    this.horasDisponiblesPorDia = [];
    this.horaDisponibleSeleccionada = '';
    this.fechaDisponibleSeleccionada = fechaDisponibilidad;

    //  NOTA: ESTE FOR SE PUEDE MEJORAR POR UN FILTER CUANDO EL SERVICIO DEVUELVA LA FECHA POR CADA DIA
    for (const item of this.listaFechasDisponiblesCitas) {
      // YYYY-MM-DD
      if (item.fecha === fechaDisponibilidad) {
        for (const horaDisponible of item.horas) {
          if (this.horasDisponiblesPorDia.findIndex(hora => hora === horaDisponible) === -1) {
            // se compara la fecha que llega por parametro con la fecha actual si son iguales
            // se validan las horas mayores a la actual y que sea mayor a 2 horas
            // es para validar disponivilidad de citas
            if (fechaDisponibilidad === moment(currentDate).format('YYYY-MM-DD')) {
              const horaDisponibleCita = moment(horaDisponible, 'h:mm');
              // se le agregan dos horas mas a la hora actual para que se autoasignen citas 2 horas despues de la actual
              // y asi evitar que se asignen con poco tiempo y evitar contratiempos
              // se dej en comentario ya que por ahora no vamos adicionar dos horas a la fecha actual
              // para agendar una cita se va a evaluar la posibilidad de hacer una configuracion mas optima con y parametrizable por el cliente
              // const horaActual = moment(currentDate, 'h:mm').add(2, 'h');
              // se agregan las horas mayores a la actual
              // if (horaDisponibleCita.isBefore(horaActual) === false) {
              if (horaDisponibleCita.isBefore(currentDate) === false) {
                this.horasDisponiblesPorDia.push(horaDisponible);
              }
            } else {
              this.horasDisponiblesPorDia.push(horaDisponible);
            }
          }
        }
      }
    }
  }

  formatHoraDisponibleCita(horaDisponible: string) {
    return moment(horaDisponible, 'HH:mm').format('hh:mm a');
  }

  /**
   * @description asigna la fecha seleciona a una variable que se utiliza al momento de asignar la cita
   * @param {string} horaSeleccionada hora disponible de la cita
   */
  seleccionarHoraDisponibleCita(horaSeleccionada: string) {
    this.horaDisponibleSeleccionada = horaSeleccionada;
    this.pasoDosForm.controls.fechaHora.setValue(horaSeleccionada);
  }

  /**
   * @description valida si existe el cliente si no se llama al servicio de crear nuevo cliente
   */
  validateCustomerCreation() {
    this.getIpCliente();
    if (this.userClient._id) {
      this.createAppointment();
    } else {
      this.createUser();
    }
  }

  /**
   * @description Se hace llamado al web services que crea al usuario
   */
  createUser() {
    const fechaNacimiento = moment(this.pasoUnoForm.get('nacimiento').value).format('YYYY-MM-DD');
    // se crea hora 00 es necesaria para formar la fecha en formato long
    const horaInicio = moment('000', 'hmm').format('HH:mm:ss.SSS');
    // .trim() quita todos los espacios al inicio y final del string
    const dataUSer = {
      usuario: {
        roles: this.constants.roles.paciente,
        nombres: this.pasoUnoForm.get('nombres').value.trim(),
        apellidos: this.pasoUnoForm.get('apellidos').value.trim(),
        nacimiento: moment(`${fechaNacimiento}T${horaInicio}`).valueOf(),
        email: this.pasoUnoForm.get('email').value.trim(),
        credencial: '1qaz2wsX', //credencial por defecto para usuarios tipo paciente
        tipo_identificacion: this.pasoUnoForm.get('tipoIdentificacion').value,
        identificacion: this.pasoUnoForm.get('identificacion').value.trim(),
        celulares: [this.pasoUnoForm.get('celulares').value.trim()],
        terminos_condiciones: true,
        estados: this.constants.state.active,
        idiomas: 'es'
      }
    };
    this.userService.postCreateUser(dataUSer, this.dataOrganization.token)
      .subscribe((response: any) => {
        // this.loading.dismiss();
        const typeResponse = response.type;
        if (typeResponse === this.constants.services.types.success) {
          this.userClient = response.data;
          // como el usuario fue creado con exito se manda a crear la cita
          this.createAppointment();
        } else {
          this.toolsService.alertErrorInformationWs(response, true, this.constants.contactAgendate.salesTeam.nameKeyTeam);
        }
      }, err => {
        // this.loading.dismiss();
        this.toolsService.statusService(err);
      }, () => {
        // this.loading.dismiss();
      });
  }

  createAppointment() {
    const fechaNgbStructValue = this.pasoDosForm.get('fechaCita').value;
    const year = fechaNgbStructValue.year;
    const month = (fechaNgbStructValue.month <= 9) ? `0${fechaNgbStructValue.month}` : fechaNgbStructValue.month;
    const day = (fechaNgbStructValue.day <= 9) ? `0${fechaNgbStructValue.day}` : fechaNgbStructValue.day;
    const fechaFormat = `${year}-${month}-${day}`;
    const date = moment(`${fechaFormat}T${this.pasoDosForm.get('fechaHora').value}`).format('YYYY-MM-DDTHH:mm');
    const fechaActual = moment().format('YYYY-MM-DDTHH:mm');
    const validarFecha = moment(date).format('YYYY-MM-DDTHH:mm');
    // validacion para verificar que la fecha y hora sean mayor o igual a la fecha actual
    if (!moment(validarFecha).isSameOrAfter(fechaActual)) {
      this.toolsService.alertNotificacionSimple(this.constants.mensajesInformativos.fechaMenorAgendarCita, null, null, 'Aceptar', this.constants.iconsSweetalert.error);
      return;
    }
    const idProcedure = this.pasoDosForm.get('tipoCita').value;
    const tipoProcedimiento = this.procedures.filter(procedure => procedure._id === idProcedure);
    const timeProcedureSelect = (tipoProcedimiento[0].tiempo_estimado) ? tipoProcedimiento[0].tiempo_estimado : 30;
    const data = {
      usuario_agendador: this.pasoDosForm.get('usuarioAtendedor').value, //this.userClient._id,
      usuario_atendedor: this.pasoDosForm.get('usuarioAtendedor').value,
      usuario_cliente: this.userClient._id,
      nota: this.pasoDosForm.get('nota').value.trim(),
      localizacion: this.dataNavegadorCliente,
      fecha: moment(date).valueOf(),
      tipos: this.tipoServicio,
      procedimientos: idProcedure,
      tiempo_estimado: timeProcedureSelect,
      retroalimentacion: {
        enterate: this.pasoDosForm.get('comoTeEnteraste').value
      },
      origen: this.constants.origenNewCita[2]
    };
    this.appointmentService.postCreateAppointment(data, this.dataOrganization.token)
      .subscribe((response: any) => {
        // this.loading.dismiss();
        const typeResponse = response.type;
        if (typeResponse === this.constants.services.types.success) {
          this.stepRegistration = 1;
          this.userClient = [];
          this.valorDelProcedimiento = '';
          this.tiempoProcedimiento = '';
          this.descripcionProcedimiento = '';
          this.toolsService.alertNotificacionSimple(this.constants.mensajesInformativos.autoAgendamientoCitaCreada);
          this.pasoUnoForm.reset();
          this.pasoDosForm.reset();
          this.resetValoresFechasCitas();
        } else if (typeResponse === this.constants.services.types.error) {
          this.toolsService.alertErrorInformationWs(response, true, this.constants.contactAgendate.salesTeam.nameKeyTeam);
        } else {
          this.toolsService.alertErrorInformationWs(response, false, this.constants.contactAgendate.supportTeam.nameKeyTeam);
        }
      }, err => {
        // this.loading.dismiss();
        this.toolsService.statusService(err);
      }, () => {
        // this.loading.dismiss();
      });
  }
}
